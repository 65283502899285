.user-item {
  text-align: center;
  img {
    border-radius: 50%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .user-details {
    margin-top: 10px;
    .user-name {
      font-weight: 600;
      color: color(dark);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-cta {
      margin-top: 10px;
      .btn {
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 30px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .user-progress .media, .user-details .media {
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  .user-progress .media img, .user-details .media img {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .user-progress .media .media-body, .user-details .media .media-body {
    width: 100%;
  }
  .user-progress .media .media-items, .user-details .media .media-items {
    margin: 20px 0;
    width: 100%;
  }
  .user-progress .list-unstyled-noborder li:last-child, .user-details .list-unstyled-noborder li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .user-progress .media {
    .media-progressbar {
      margin-top: 10px;
    }
    .media-cta {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}