.pricing {
  @include card-base;
  text-align: center;
  &.pricing-highlight {
    .pricing-title {
      background-color: color(primary);
      color: #fff;
    }
    .pricing-cta {
      a {
        background-color: color(primary);
        color: #fff;
        &:hover {
            background-color: color_darken(primary, 10%) !important;
        }
      }
    }
  }
  .pricing-padding {
    padding: 40px;
  }
  .pricing-title {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    background-color: color_lighten(light, 5%);
    color: color(primary);
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding: 5px 15px;
  }
  .pricing-price {
    margin-bottom: 45px;
    div:first-child {
      font-weight: 600;
      font-size: 50px;
    }
  }
  .pricing-details {
    text-align: left;
    display: inline-block;
    .pricing-item {
      display: flex;
      margin-bottom: 15px;
      .pricing-item-icon {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        background-color: color(success);
        color: #fff;
        margin-right: 10px;
        i {
          font-size: 11px;
        }
      }
    }
  }
  .pricing-cta {
    margin-top: 20px;
    a {
      display: block;
      padding: 20px 40px;
      background-color: color_lighten(light, 5%);
      text-transform: uppercase;
      letter-spacing: 2.5px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      border-radius: 0 0 3px 3px;
      .fas, .far, .fab, .fal, .ion {
        margin-left: 5px;
      }
      &:hover {
        background-color: color(light);
      }
    }
  }
}