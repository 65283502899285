.activities {
  display: flex;
  flex-wrap: wrap;
  .activity {
    width: 100%;
    display: flex;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: 25px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: color(primary);
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    .activity-icon {
      @include box();
      margin-right: 20px;
      border-radius: 50%;
      flex-shrink: 0;
      text-align: center;
      z-index: 1;
    }
    .activity-detail {
      @include card_base;
      position: relative;
      padding: 15px;
      &:before {
        content: '\f0d9';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 20px;
        position: absolute;
        left: -8px;
        color: #fff;
      }
      h4 {
        font-size: 18px;
        color: color(dark);
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}