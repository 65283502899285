#toast-container > {
  div {
    @include shadow;
    padding: 20px 20px 20px 50px;
    opacity: 1;
  }
  .toast {
    background-image: none !important;
    &:before {
      position: absolute;
      left: 17px;
      top: 25px;
      font-family: 'Ionicons';
      font-size: 24px;
      line-height: 18px;
      color: #fff;
    }
  }
  .toast-warning:before {
    content: "\f100";
  }
  .toast-error:before {
    content: "\f2d7";
  }
  .toast-info:before {
    content: "\f44c";
    color: #000;
  }
  .toast-success:before {
    content: "\f121";
  }
}

.toast {
  &.toast-error {
    background-color: color(danger);
  }
  &.toast-warning {
    background-color: color(warning);
  }
  &.toast-success {
    background-color: color(success);
  }
  &.toast-info {
    background-color: #fff;
    .toast-title {
      color: #000;
    }
    .toast-message {
      color: #000;
      margin-top: 5px;
    }
  }
}